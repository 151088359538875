@import url('https://fonts.googleapis.com/css?family=Dosis|Yellowtail'); // feel free to change this font type at any momment.

$primary: #0b5cbe; /* MAIN COLOR */
$secondary: #3490ec; /* SECONDARY COLOR */
$gray: #666; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$footerLinks: #d5d5d5;

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


body {
	font-size: 1.5em;
	overflow-x: none;

	h1,h2,h3,h4,h5,h6 {
		font-size:2em;
	}
}

nav {
	z-index: 10;
}
.navbar-brand {
    height: 100px; // this is what determine the height of the nav
    padding: 5px;
		@media(max-width: 767px){
			height: 70px; // nav height mobile,(feel free to change this values as you please)
			.logo {
				max-height: 60px; // logo height changed on mobile
			}
		}
}
.navbar .navbar-nav {
> li > a {
			font-family: 'Dosis', sans-serif;
			font-size: 26px;
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: black;
			transition: .5s all ease;


			@media (max-width: 767px) {
				margin-top: 0;
			    display: inline-block;
			    height: 25px;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
}
.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

// here start mixins ---- this is a style for buttons you can add it to your a tag like this (@include btn, look at the .btn-default style), these values can be changed anytime you need to.
@mixin btn{
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: 0px;
	background: $primary;
	color: $wht;
	padding: 0.5em 1em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 2em 0em;

	&:hover {
		background: lighten($primary,10%);
		color: $wht;
		box-shadow: 0px 0px 3px lighten($blk,20%);
		text-decoration: none;

	}
}

.btn-default {
	@include btn;
}
// mixins end here


// sections start
.section-a {
	padding: 60px;
	margin-top: 100px; // this marging  will depend on the height of the nav, (if you have a bigger or smaller nav feel free to change it)  'if you need a transparent nav and see the img change margin to padding'

}
// sections end


.modal-dialog {
	max-width: 500px;
	width: 100%;
	text-align: center;
	margin: 20em auto;

	@media (max-width: 767px) {
		margin: 10em auto;
	}

	.close {display: none;}

	.modal-content {
		font-family: 'Dosis', sans-serif;
		color: $primary;
		font-size: 22px;

		h2 {
			margin-top: -50px;
			font-family: 'Yellowtail', cursive;
			text-align:center;
		}
	}

	a.btn, a.btn-default, button {
		@include btn
		margin: 0;
		display: block;
	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}


footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;
	
	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;

		&:hover {
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}
		
		&:focus {
			color: $footerLinks;
			outline: 0 !important;
 			text-decoration: none;
		}		
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}	
}



.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    }
}

.container-fluid {
	padding-left: 0;
	padding-right: 0;
}

.title-container {
	z-index:1;
	position:absolute;
	top:35%;
	width:100%;
	text-align:center;
	color:#fff;

	@media (max-width: 990px) {
		top: 25%;
	}

	@media (max-width: 990px) and (orientation: landscape) {
		top: 30%;
	}
}

/* FOR IE8 */
video {
	width: 100% !important;
	height: 100% !important;
	object-fit: cover;

	@media (max-width: 767px) {
		width: auto;
	}
}
/* END IE8*/

.videoBox {
 	position: relative;
 	width: 100%;
 	height: 900px;
 	overflow: hidden;

 	@media (max-width: 767px) {
 		height: 800px;
 	}
}

.video-overlay {
 position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    transition: all 1s ease;
    

    h1 {
     position: absolute;
     left: 50%;
     top: 50%;
     transform: translateX(-50%) translateY(-50%);
     text-transform: uppercase;
     text-align: center;
    }
}

.title {
	font-family: 'Yellowtail', cursive;
	font-size: 70px;

	@media(max-width: 767px) {
		font-size: 40px;
	}
}

.headline {
	width: 100%;
}

.grid {
	position: relative;
	margin: 0 auto;
	padding: 1em 0 4em;
	max-width: 100%;
	list-style: none;
	text-align: center;

	@media (max-width: 1280px) {
		margin: 10px 25% !important;
	}
 
	@media (max-width: 990px) {
		margin: 10px 25% !important;
	}

	@media (max-width: 767px) {
		margin: 10px 17% !important;
	}

	@media (max-width: 550px) {
		margin-right: 20px !important;
		margin: 20px 8%;
	}

	@media (max-width: 350px) {
		margin-right: 10px !important;
		margin: 20px -5%;
		max-width: 120%;
	}
}

/* Common style */
.grid figure {
	display: flex;
	position: relative;
	float: left;
	overflow: hidden;
	margin: 10px 4%;
	top: -20px;
	min-width: 320px;
	max-width: 475px;
	max-height: 360px;
	background: #3085a3;
	text-align: center;
	cursor: pointer;
	width: 500px;
	height: 500px;

	@media (max-width: 990px) {
		height: 300px;
		width: 250px !important;
		margin: 10px -29px;
	}

	@media (max-width: 767px) {
		margin: 10px 15%;;
	}

	@media (max-width: 670px) {
		margin: 10px 5% !important;
	}

	@media (max-width: 550px) {
		margin: 10px 0% !important;
	}

	@media (max-width: 450px) {
		margin: 10px -20% !important;
	}

	@media (max-width: 375px) {
		margin: 10px -17% !important;
	}

	@media (max-width: 340px) {
		margin: 10px -29% !important;
	}
}

.grid figure img {
	position: relative;
	display: block;
	min-height: 100%;
	height: 100%;
	max-width: 100%;
	opacity: 0.8;
}

.grid figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	@media (max-width: 767px) {
		margin-top: -20px !important;
		font-size: 1em;
	}
}

.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

.grid figure h2 {
	word-spacing: -0.15em;
	font-weight: 300;

	@media (max-width: 1280px) {
		word-spacing: 0;
		font-size: 30px !important;
		line-height: 30px;
	}

	@media (max-width: 767px) {
		font-size: 30px;
		margin-top: 20px !important;
		text-align: center;
	}
}

.grid figure h2 span {
	font-weight: 800;
}

.grid figure h2,
.grid figure p {
	margin: 0;
}

.grid figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}

/*---------------*/
/***** Dexter *****/
/*---------------*/

figure.effect-dexter {
	background: -webkit-linear-gradient(top, rgba(11,92,190,1) 0%, rgba(52,134,236,1) 100%);
	background: linear-gradient(to bottom, rgba(11,92,190,1) 0%,rgba(52,134,236,1) 100%); 
}

figure.effect-dexter img {
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;

	@media (max-width: 990px) {
		opacity: 0.3;
	}
}

figure.effect-dexter:hover img {
	opacity: 0.1;
}

figure.effect-dexter figcaption::after {
	position: absolute;
	right: 30px;
	bottom: 30px;
	left: 30px;
	height: -webkit-calc(50% - 30px);
	height: calc(50% - 30px);
	border: 7px solid $wht;
	content: '';
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0,-100%,0);
	transform: translate3d(0,-100%,0);

	@media (max-width: 1280px) {
		border: none;
		height: calc(50% - 30px) !important;

	}
	@media (max-width: 990px) {
		bottom: 5px;
		top: 25%;
		height: calc(50% + 30px);
		margin-top: 50px;
		-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
	}
}

figure.effect-dexter:hover figcaption::after {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

figure.effect-dexter figcaption {
	padding: 3em;
	text-align: left;

	@media (max-width: 767px) {
		padding: 1em;
		text-align: center;
	}
}

figure.effect-dexter p {
	position: absolute;
	right: 60px;
	bottom: 60px;
	left: 60px;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(0,-100px,0);
	transform: translate3d(0,-100px,0);

	@media (max-width: 1280px) {
		font-size: 13px !important;
		line-height: 17px;
	}

	@media (max-width: 990px) {
		bottom:40px !important;
		text-align: center;
		font-size: 15px;
		-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
		opacity: 1;
	}
	
	@media (max-width: 767px) {
		bottom: 10px;
		font-size: 13px;
		-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
		opacity: 1;
	}

	@media (max-width: 500px) {
		bottom: 50px;
	}
}

figure.effect-dexter:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

.container-fluid {
	padding-left: 0;
	padding-right: 0;
}

.services {
	background: url('../img/servicesbg.jpg');
	background-size: cover;

	@media (max-width: 1200px) {
		background-position: 60% 0;
	}
}

.about {
	background: url('../img/aboutbg.jpg');
	background-size: cover;
	background-attachment: fixed;
	background-position: 0% 40%;
	padding: 400px 0;

	@media (max-width: 990px) {
		background-attachment: scroll;
		padding: 200px 0;
	}

}

.aboutTitle {
	font-family: 'Dosis', sans-serif;
	font-size: 80px;
	color: $wht;

	@media (max-width: 990px) {
		font-size: 40px;
	}
}

.aboutTxt {
	font-family: 'Dosis', sans-serif;
	font-size: 20px;
	line-height: 50px;
	color: $wht;

	@media (max-width: 767px) {
		line-height: 30px;
	}
}

.contact {
	background: $secondary;
	padding: 100px 0;
	h3 {
		font-family: 'Dosis', sans-serif;
		font-weight: 900;
		font-size: 45px;
		color: $wht;

		@media (max-width: 767px) {
			font-size: 32px;
		}
	}

	ul {
		margin-left: -35px;
	}

	li {
		margin-left: -10px;
		list-style-type: none;
	}

	a {
		color: $primary;
		text-decoration: none;
	}

	p {
		font-family: 'Dosis', sans-serif;
		color: $wht;
		font-size: 22px;

		@media (max-width: 767px) {
			font-size: 16px;
		}
	}
}

.row {
	margin-left: 0;
	margin-right: 0;
}